.about{
    background: #191919;
}
img.about__img{
    border-radius: 10px;
    width: 100%;
}
h1.about__heading{
    margin-bottom: 10px;
    font-weight: 700;
    text-align: start;
    font-family: Montserrat,sans-serif;
    font-size: 60px;
}
button.about.btn.pointer {
    text-transform: capitalize;
    width: 220px;
    height: 55px;
    font-size: 18px;
    transition: .4s ease;
}
.about__button{
    padding: 20px 0px;
}
a.bottom__to__top{
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 3%;
    right: 2%;
    background-color: #F9004D;
    border-radius: 50%;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    transition: .4s all ease;
    z-index: 1000;
}
a.bottom__to__top.active{
    display: flex;
}


/* RESPONSIVE */
@media only screen and (max-width: 1024px){
    .p__color{
        font-size: 15px!important;
    }
}
@media only screen and (max-width: 425px){
button.about.btn.pointer {
    text-transform: capitalize;
    width: 150px;
    height: 50px;
    font-size: 11px;
    transition: .4s ease;
    padding-left: 12px;
}
}

