.Skills{
  background-color: #1a1919;
}
.skills {
    max-width: 68.75rem;
    margin: auto;
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 2.43rem;
  }
  
  .skill-header {
    margin-bottom: 1rem;
    font-size: 5rem;
  }
  
  .skills-wrapper img {
    padding: 1.25rem;
  }
  
  .icon {
    width: 10rem;
    height: 10rem;
    
  }

  .icon-card {
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 3px 10px var(--secondary-shadow);
    padding: 2rem;
    margin: 1rem 1rem;
  }

  .icon:hover{
    transition: transform .5s ease;
  }
  .icon:hover {
    cursor: pointer;
    transform: scale(1.2);
  }