.home__bg {
    background-image: url(C:\Users\DELL\Desktop\Portfolio\portfolio-project-main\src\img\ali.jpg);
    background-color: #010101;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

li.nav__items {
    font-size: 18px;
    padding-bottom: 15px;
}

.home__content {
    padding: 125px 0px;
}

h1.home__text {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 3px;
    color: rgba(198, 201, 216, .75) !important;
}

h2.home__text {
    font-size: 54px;
    line-height: 54px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
}

h3.home__text {
    font-size: 50px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    line-height: 40px;
}

h4.home__text {
    font-size: 50px;
    font-weight: 800;
    line-height: 40px;
    font-family: Montserrat, sans-serif;

}

.header {
    transition: .4s all ease;
}

.header.active {
    position: fixed;
    z-index: 1000;
    width: 100%;
    padding: 0px 30px;
    background-color: #191919;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-item {
    margin-left: 2rem;
}

.nav-link {
    font-size: 1.3rem;
    font-weight: 400;
    color: #fbfcfd;
}

.nav-link:hover {
    color: crimson;
}

.nav-logo {
    font-size: 2.5rem;
    font-weight: 500;
    color: crimson;
    font-family: 'Lobster', cursive;
}

ul.sidebar.d__flex {
    flex-wrap: wrap;
    height: 100%;
    position: fixed;
    right: 0%;
    background: #010101;
    justify-content: center;
    width: 300px;
    top: 0%;
    flex-direction: column;
}

li.sideNavbar {
    padding: 25px 30px;
}
.home .home-content .text-3{
    font-size: 40px;
    margin: 5px 0;
}
.home .home-content .text-3 span{
    color: crimson;
    font-weight: 500;
}

/* RESPONSIVE */
@media only screen and (max-width: 768px) {
    .header {
        justify-content: space-between;
    }
    .header.active {
        position: fixed;
        z-index: 1000;
        width: 402px;
        padding: 0px 30px;
        background-color: #191919;
    }
    .home__bg{
        width: 401px;
    }

    .navigation.pxy__30 {
        display: none;
    }

    h2.white.pz__10 {
        font-size: 50px !important;
    }

    svg.bi.bi-justify.white.pointer {
        width: 30px;
        height: 30px;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 2rem;
        flex-direction: column;
        background-color: rgb(24, 22, 22);
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        padding-left: 13rem;
    }

}

@media only screen and (max-width: 425px) {
    h2.home__text.pz__10 {
        font-size: 32px;
    }

    h3.sweet.pz__10 {
        font-size: 40px;
    }

    h1.home__text {
        font-size: 14px;
    }

    h4.home__text {
        font-size: 45px;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

}