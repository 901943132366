.project {
    background-color: #191919;
}

.project_heading {
    margin-bottom: 10px;
    font-weight: 700;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 60px;
}

img.project__img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    transition: .4s;
    opacity: .7;
    position: relative;
}

img.project__img::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 1;
    opacity: 1;
}

.mask__effect {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f9004d;
    border-radius: 10px;
    transition: .4s;
    opacity: .3;
    display: none;
}

.project__box:hover .mask__effect {
    display: inline;
    transform: scale(1.1);
}

.project__box:hover img.project__img {
    transform: scale(1.1);
}

.project__meta.absolute {
    top: 0%;
    left: 0%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    width: 100%;
}

h1.heading {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: -webkit-xxx-large;
}

h5.project__text {
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 20px;
}

h4.project__text {
    font-size: 22px;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    padding-bottom: 20px;
    margin-left: 25px;
}

a.project__btn {
    padding: 12px 35px;
    border: 2px solid #ffffff87;
    border-radius: 5px;
    transition: .4s ease;
}

a.project__btn:hover {
    background-color: #f9004d;
    border: 2px solid #f9004d;
}

button.btn {
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    border: 2px solid #f9004d;
    background-color: #f9004d;
    color: #fff !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: .4s all ease;
}

button.view__more:hover {
    background-color: #fff;
    border: 2px solid #f9004d;
    color: #f9004d !important;
}

/* RESPONSIVE */
@media only screen and (max-width: 1024px) {
    h5.project__text {
        font-weight: 400;
        font-size: 20px;
        padding-bottom: 20px;
    }

    h4.project__text {
        font-size: 18px;
    }
}